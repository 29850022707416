import { render, staticRenderFns } from "./link-add-or-update.vue?vue&type=template&id=653677e5&"
import script from "./link-add-or-update.vue?vue&type=script&lang=js&"
export * from "./link-add-or-update.vue?vue&type=script&lang=js&"
import style0 from "./link-add-or-update.vue?vue&type=style&index=0&id=653677e5&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports